
import { Component, Vue, Prop } from 'vue-property-decorator';

import NavigationLink from './NavigationLink.vue';

@Component({
  components: {
    NavigationLink,
  },
})
export default class Header extends Vue {
  @Prop()
  private readonly mobileNavActive!: boolean;

  @Prop()
  private readonly isStuck!: boolean;

  private navCurrent = '';

  handleOnClicked(clicked: string) {
    this.navCurrent = clicked;
  }
}
